import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "new-packet"
    }}>{`New Packet`}</h1>
    <p>{`Creates a new Packet.`}</p>
    <p><strong parentName="p">{`POST`}</strong>{` `}<inlineCode parentName="p">{`/packets/new`}</inlineCode></p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <p>{`The NewPacket POST data request is formatted as shown below (can be provided in any order)
Fields marked with an asterisk (`}{`*`}{`) will be added by the SignPenFree application and do not need to be included by the client api call.`}</p>
    <p>{`The entire POST data passed to SPF in the NewPacket call will be returned to the client on a Packet_Created_Callback_URL and a packetSignedCallbackUrl, less the API Key value.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account's API Key creating this packet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Persons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Array. Every packet must have at least 1 signer to be valid. This is a list of all signers, readers and receivers for any document in this packet. Maximum of 30 Persons per packet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Documents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Array. The array of documents to be included in the packet. Must contain at least 1 document. Will be displayed to signers or readers in the in same order as listed here. Maximum of 20 documents per packet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`My_Custom_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Associative Array. Any custom fields corresponding with the packet. The purpose of these is to allow a client to pass client-specific information and have it returned with each callback. The key portion of the array element can be up to 255 characters and must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`". The value portion of the array element can be up to 100 characters and must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",". A custom field must contain a key but is not required to have a value - the value can be an empty string. There can be a maximum of 30 custom fields per packet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Sequential_Signing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 0. Used as a logical boolean to indicate if sequential signing or not. If not sequential signing then concurrent signing, which is the default`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Expiration_Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Y-m-d format. The date the packet expires if not otherwise signed or completed. Defaults to 3 days after date created if not specified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Created_Callback_URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. The maximum length of 255 characters. If provided this is the URL that will be executed when packet creation is complete. The entire POST data passed to SPF in the NewPacket call will be returned to the client, less the API Key value, and there will be no direct response. If not provided, the response will be direct back to the call via direct response.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Action_Callback_URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. The maximum length of 255 characters. If provided this is the URL that will be executed when packet status changes. The entire POST data passed to SPF in the NewPacket call will be returned to the client, less the API Key value. See packet_actions for a list of valid actions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`packetSignedCallbackUrl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. Maximum length of 255 characters. If provided this is the URL that will be executed when packet signing is completed. The entire POST data passed to SPF in the NewPacket call will be returned to the client, less the API Key value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. Each element/string in the array will be applied as a tag for this packet. These tags can then be used when logged in to your SPF account to filter packets by tag. Each packet can have up to 10 tags.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Id `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. String. A unique ID used to identify each packet. Returned by SPF when a new packet is created. An empty string if an error occurred or if the packet wasn't created. This is the value that can be used by the API client to refer to a specific packet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error_Messages `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. Array. An array of one or more error messages. An empty array if no error occurred.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "packet-tags"
    }}>{`Packet Tags`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Tag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Any string of up to 80 characters. Must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",".`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h4 {...{
      "id": "persons"
    }}>{`Persons`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`First_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The person's first name. Will be used for default signature but signature can be changed by signer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Middle_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. The person's middle name. Will be used for default signature but signature can be changed by signer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The person's last name. Will be used for default signature but signature can be changed by signer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. The person's text compatible Phone number. If not provided then Email must be provided. If provided, SignPenFree will attempt to send messages via text to this number if/when appropriate. Only US Phone numbers are allowed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. The person's Email address. If not provided then Phone must be provided. If provided, SignPenFree will attempt to send messages via Email to this Email address if/when appropriate.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Deactivate_Prior_Packets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. Used as a logical boolean to indicate if prior documents sent to this same person from this same account are to be deactivated (Active set to 0) when this new packet is created. Email is the only field used to identify a "same person" for the use of this.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is_Signer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. 1=this person is a document signer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is_Reader`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 0. 1=this person is a document reader (not a signer). Will be 0 is Is_Signer is 1.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is_Receiver`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. 1=this person may be emailed the documents when completed. (Ex: This could be an online storage location that will receive a copy of the documents when completed, but as a link not as an attachment.) Will be 0 is Is_Signer is 1 or Is_Reader is 1.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Signer_Num`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Integer. Example: "1" will correspond to `}{`[signature1]`}{` or `}{`[signature01]`}{` etc. If not provided will default to "1".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simple_Sign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. 1=use simple signing methodology for signing documents. Simple Sign does not require PIN authentication and allows signing with 1 click for all signatures, versus once for each signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expiration_Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Y-m-d format. The date this person expires (set to Inactive) for this packet if not otherwise signed or completed. Defaults to 3 days after date created if not specified.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Send_Request_To_Sign_Msg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. 1=SPF to send message(s) to signer(s).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Request_To_Sign_Msg_Body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. A string to use as the message body in the Email and text message sent by SPF to request a person to sign their documents. This does not affect the greeting and the request to click the link or button. This only applies when send`}<em parentName="td">{`request_to_sign_msg = 1. If not provided, SPF will use it's standard/default message. Valid characters include a-z, A-Z, 0-9, dash "-" underscore "`}</em>{`", space " ", left parenthesis "(", right parenthesis")", single quote "'", comma ",", period ".", colon ":", semicolon ";", exclamation mark "!", question mark "?", and equal sign "=".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Thank_You_Msg_Body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. A string to use as the "thank you" message that is displayed to a signer after signing their documents. If not provided, SPF will use it's standard/default message. Valid characters include a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", single quote "'", comma ",", period ".", colon ":", semicolon ";", exclamation mark "!", question mark "?", and equal sign "=".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Read_Only_After_Completion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 0. Applies only to readers, not to signers. if 1=reader will be allowed to read only after documents are signed/completed, not prior or if expired prior to completion.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email_Completed_Docs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. 1=SPF will Email completed docs to person after the documents in the packet are completed/signed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email_Cert_Of_Comp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 0. 1=SPF will Email the certificate of completion/authentication document after all documents in the packet are completed/signed, to appropriate Persons.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Signer_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. Custom signer fields and corresponding values and properties. These only apply to signers, not to readers. These fields will be displayed at the top of the documents page when the signer accesses the documents. Maximum of 10 signer fields, any additional will be ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Signer_Custom_Consents`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. Custom signer consents and corresponding properties. These only apply to signers, not to readers. These will be displayed at the top of the documents page when the signer accesses the documents. Maximum of 3 consents, any additional will be ignored.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Person_Tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. Each element/string in the array will be applied as a tag for this packetPerson. These tags can then be used when logged in to your SPF account to filter Persons by tag. A maximum of 10 tags per person.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`My_Custom_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Any custom fields corresponding with each person. The purpose of these is to allow a client to pass client-specific information and have it returned with each callback. The key portion of the array element can be up to 100 characters and must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}<em parentName="td">{`". The value portion of the array element can be up to 255 characters and must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}</em>{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",". A custom field must contain a key but is not required to have a value - the value can be an empty string. There can be a maximum of 30 custom fields per person.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Person_Id `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. String. A unique ID used to identify each person. Returned by SPF when a new packet is created. An empty string if an error occurred or if the packet wasn't created or if the person wasn't valid. This is the value that can be used by the client to refer to a specific person.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Person_View_URL `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. String. This value is generated and returned by SPF when a new packet is created. Each person's unique URL to view/access the documents in the packet. This is the URL/link that can be used by the client to allow a person to open/view their documents.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "signer-fields"
    }}>{`Signer Fields`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Field_Tag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Any field tag max of 50 characters. No HTML allowed. Example: "`}{`[this_field_tag]`}{`". Each field tag must be in square brackets. This is a field located in the document content. A field`}<em parentName="td">{`tag must contain a value. Valid characters include a-z, A-Z, 0-9, dash "-" and underscore "`}</em>{`".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Field_Label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Maximum of 30 characters. The label that will correspond with this field in the signer interface. A field`}<em parentName="td">{`tag must contain a value. Valid characters include a-z, A-Z, 0-9, dash "-" and underscore "`}</em>{`".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Field_Default_Value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. Maximum of 255 characters. No HTML allowed. The value (or default value if the field is editable) corresponding with field tag. Example: "123 Any Street". Valid characters include a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", single quote "'", comma ",", period ".", colon ":", semicolon ";", exclamation mark "!", question mark "?", and equal sign "=".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is_Value_Editable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 0 if not specified. 1=the signer will be allowed to edit the value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is_Value_Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 0 if not specified. 1=the signer must provide at least 1 character for this field.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "signer-custom-consents"
    }}>{`Signer Custom Consents`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consent_Label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Maximum of 20 characters. The label to print on the Certificate of Authentication document and prepended to the text on the signing page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consent_Text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Maximum of 120 characters. The text of the consent (term or condition) that the signer will be asked to consent/agree to when signing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consent_Default`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1 if not specified. 1=the signer checked/Consented/agreed to the item. If using Simple Sign, this will always default to 1.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consent_Required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1 if not specified. 1=the signer must check/consent/agree to the item in order to complete the signing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consented `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. Integer 1/0. This value is generated and returned by SPF when a new packet is signed. It will be a 1 if the signer Consented and a 0 if the signer did not consent. Obviously it will not be 0 if Consent_Required is 1, because in that situation the signing will not be allowed.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "person-tags"
    }}>{`Person Tags`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Person_Tag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Any string of up to 80 characters. Must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",".`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h4 {...{
      "id": "documents"
    }}>{`Documents`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Google_Drive_File_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Google Drive File ID of the Google Drive file that is to be signed. The file can be located virtually anywhere, but the SignPenFree account "`}<a parentName="td" {...{
              "href": "mailto:docstosign@signpenfree.com"
            }}>{`docstosign@signpenfree.com`}</a>{`" must have read access to the file so that SignPenFree can copy the file.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Document_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The name as to displayed to the user, and in the account interface. Maximum length is 80 characters. Must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email_Doc_When_Complete`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Integer 1/0. Defaults to 1. 1=this document is eligible to be emailed by SPF to signers, readers and/or receivers when complete; else it is not eligible.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Document_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. A list of custom document fields and corresponding values. Before sending a document to a signer to be signed, SignPenFree will first replace each listed field with the corresponding value. Maximum of 20 fields per document.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Document_Tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. Each element/string in the array will be applied as a tag for this packetDocument. These tags can then be used when logged in to your SPF account to filter documents by tag. A maximum of 10 tags per document.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`My_Custom_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Any custom fields corresponding with each document. The purpose of these is to allow a client to pass client-specific information and have it returned with each callback. The key portion of the array element can be up to 100 characters and must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}<em parentName="td">{`". The value portion of the array element can be up to 255 characters and must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}</em>{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",". A custom field must contain a key but is not required to have a value - the value can be an empty string. There can be a maximum of 30 custom fields per document.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Document_Id `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. String. A unique ID used to identify each packetDocument. Returned by SPF when a new packet is created. An empty string if an error occurred or if the packet wasn't created or if the document wasn't valid. This is the value that can be used by the client to refer to a specific document.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Completed_Document_Link `}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SignPenFree. String. A link/url to the completed/signed PDF document. This will only contain a value on a callback that occurs after a packet has been completed/signed.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "document-fields"
    }}>{`Document Fields`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Field_Tag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Any field tag max of 50 characters. No HTML allowed. Example: "`}{`[this_field_tag]`}{`". Each field tag must be in square brackets in the document to be signed. It is optional whether or not you include the square brackets in the field`}<em parentName="td">{`tag because they will automatically be added regardless. A Field_Tag must contain a value. Valid characters include a-z, A-Z, 0-9, dash "-" and underscore "`}</em>{`".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Field_Value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Maximum of 255 characters. No HTML allowed. The value corresponding with field tag. Example: "123 Any Street". It is acceptable to have an empty value. Valid characters include a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", single quote "'", comma ",", period ".", colon ":", semicolon ";", exclamation mark "!", question mark "?", and equal sign "=".`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "document-tags"
    }}>{`Document Tags`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Document_Tag`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. Any string of up to 80 characters. Must contain only valid characters, which include: a-z, A-Z, 0-9, dash "-" underscore "`}{`_`}{`", space " ", left parenthesis "(", right parenthesis")", period"." and comma ",".`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <p><strong parentName="p">{`Example Response`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Result": "1",
  "Error_Messages": {},
  "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
  "Data": {
    "Packet_Id": "7fcd9487-7b0e-4545-a53a-15bd77bb5b6a",
    "Packet_Sequential_Signing": "0",
    "Packet_Expiration_Date": "2021-04-17",
    "Packet_Created_Callback_URL": "https://yourwebsite.com/callback/created",
    "Packet_Action_Callback_URL": "https://yourwebsite.com/callback/action",
    "packetSignedCallbackUrl": "https://yourwebsite.com/callback/signed",
    "Documents": {
      {
        "Packet_Document_Id": "e41de50b-397e-45ee-a2f1-e214eceae4fc",
        "Completed_Document_Link": "",
        "Google_Drive_File_Id": "1ezbiCl3PFB08AlRCS-DcBzIboKn393Jw1VJWeeZjNQQ",
        "Document_Name": "Legal Document",
        "Email_Doc_When_Complete": "1",
        "Document_Fields": {},
        "My_Custom_Fields": {
          "thisDocumentId": "902"
        }
      }
    },
    "Persons": {
      {
        "Packet_Person_Id": "57470dd9-bb69-4539-8b7b-6c6909634291",
        "Packet_Person_View_URL": "https://esign.run/qr482OhI",
        "First_Name": "John",
        "Middle_Name": "",
        "Last_Name": "Doe",
        "Email": "john.doe@website.com",
        "Phone": "5124009186",
        "Deactivate_Prior_Packets": "0",
        "Is_Reader": "0",
        "Is_Signer": "1",
        "Signer_Num": "1",
        "Send_Request_To_Sign_Msg": "0",
        "Read_Only_After_Completion": "0",
        "Simple_Sign": "1",
        "Expiration_Date": "2021-04-17",
        "Email_Completed_Docs": "1",
        "Signer_Fields": {},
        "Signer_Custom_Consents": {},
        "My_Custom_Fields": {
          "thisPersonId": "20399021"
        }
      }
    },
    "My_Custom_Fields": {
      "callbackAuthKey": "Az8ZK6W7tMm5JBX639xiAsm12^Lp2j"
    }
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      